// src/components/Footer.js
import { Box, Button, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import rightDecoration from '../assets/footer-right.svg';
import startButtonImage from '../assets/start.svg';

function Footer({ countdownEnded, countdownSectionRef }) {
  const handleClick = () => {
    if (countdownEnded) {
      // Open the link if the countdown has ended
      window.open("https://kahoot.it/challenge/06624298", "_blank");

      // window.open("https://kahoot.it/challenge/03818279", "_blank");


    } else if (countdownSectionRef.current) {
      // Scroll to the countdown section if the countdown is still active
      countdownSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (countdownEnded) {
      // Optionally, handle any action when countdown ends (e.g., updating UI)
    }
  }, [countdownEnded]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '100px',
        position: 'relative',
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        backdropFilter: 'blur(3px)',
        borderRadius: '15px',
        fontFamily: "'Noto Sans Arabic', sans-serif",
        overflow: 'hidden',
      }}
    >
      {/* Right Decoration Image */}
      <Box component="img" src={rightDecoration} alt="Right Decoration" sx={{ position: 'absolute', right: 0, height: '100%', zIndex: 1 }} />

      {/* Button with Image */}
      {!countdownEnded && (
  <Button
    href="https://kahoot.it/challenge/01943247"
    target="_blank"
    sx={{
      backgroundColor: '#eb6445',
      borderRadius: '30px',
      padding: { xs: '10px 8px', md: '30px 40px' },
      zIndex: 2,
      mb: 2,
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
      '&:hover': {
        backgroundColor: '#c9553a',
      },
    }}
  >
    <Typography sx={{ color: 'white', fontSize: { md: 50, xs: '2em' } }}>
      امتحان تجريبي
    </Typography>
  </Button>
)}

      {/* Button with Image */}
      <Button
        onClick={handleClick}
        sx={{
          backgroundColor: '#FBC029',
          borderRadius: '30px',
          padding: { xs: '15px 40px', md: '40px 120px' },
          zIndex: 2,
          mb: 2,
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
          '&:hover': {
            backgroundColor: '#e0a828',
          },
        }}
      >
        <Box component="img" src={startButtonImage} alt="Start" sx={{ width: { xs: '80px', md: '120px' }, height: 'auto' }} />
      </Button>
   <Typography variant="h12" sx={{ color: '#064DA1', fontWeight: 'bold', textAlign: 'center', mt: 2, zIndex: 2, fontSize: { xs: '1.5rem', md: '2rem' } }}>
يرجى الدخول باستخدام رقم الهوية الخاص بك، حيث لن يُسمح بالمشاركة في المسابقة إذا تم إدخال الاسم.
      </Typography>
      {/* Explanatory Text */}
      <Typography variant="h5" sx={{ color: '#064DA1', fontWeight: 'bold', textAlign: 'center', mt: 2, zIndex: 2, fontSize: { xs: '1.5rem', md: '2rem' } }}>
        لتبدأ المسابقة اضغط هنا
      </Typography>
    </Box>
  );
}

export default Footer;


// src/components/Footer.js




// import { Box, Button, Typography } from '@mui/material';
// import React, { useEffect } from 'react';
// import rightDecoration from '../assets/footer-right.svg';
// import startButtonImage from '../assets/start.svg';

// function Footer({ countdownEnded, countdownSectionRef }) {
//   // Links based on ISO date ranges
//   const links = [
//     { start: "2024-11-26T00:00:00Z", end: "2024-11-26T23:59:59Z", link: "https://kahoot.it/challenge/02545815" },
//     { start: "2024-11-27T09:00:00Z", end: "2024-11-27T12:00:00Z", link: "https://kahoot.it/challenge/06945401" },
//     { start: "2024-11-28T12:00:00Z", end: "2024-11-28T13:00:00Z", link: "https://kahoot.it/challenge/07594222" },
//     { start: "2024-11-29T09:00:00Z", end: "2024-11-29T12:00:00Z", link: "https://kahoot.it/challenge/05189607" },
//     { start: "2024-11-30T09:00:00Z", end: "2024-11-30T12:00:00Z", link: "https://kahoot.it/challenge/05836392" },
//     { start: "2024-12-01T09:00:00Z", end: "2024-12-01T12:00:00Z", link: "https://kahoot.it/challenge/03818279" },
//   ];

//   const handleClick = () => {
//     const now = new Date().toISOString(); // Current time in ISO format

//     if (countdownEnded) {
//     // Check for matching link
//     const matchedLink = links.find(({ start, end }) => now >= start && now <= end);

//     if (matchedLink) {
//       window.open(matchedLink.link, "_blank");
//     }else{
//       window.open("https://drive.google.com/drive/my-drive", "_blank");
//     }
//     }  else if (countdownSectionRef.current) {
//       countdownSectionRef.current.scrollIntoView({ behavior: "smooth" });
//     }
//   }
//   useEffect(() => {
//     if (countdownEnded) {
//       // Automatically navigate to the link when the countdown ends
//       window.open("https://www.britannica.com/topic/European-Union", "_blank");
//     }
//   }, [countdownEnded]);

//   return (
//     <Box
//       sx={{
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center',
//         flexDirection: 'column',
//         padding: '100px',
//         position: 'relative',
//         backgroundColor: 'rgba(255, 255, 255, 0.2)',
//         backdropFilter: 'blur(3px)',
//         borderRadius: '15px',
//         fontFamily: "'Noto Sans Arabic', sans-serif",
//         overflow: 'hidden',
//       }}
//     >
//       {/* Right Decoration Image */}
//       <Box
//         component="img"
//         src={rightDecoration}
//         alt="Right Decoration"
//         sx={{ position: 'absolute', right: 0, height: '100%', zIndex: 1 }}
//       />

//       {/* Button for Trial Exam */}
//       <Button
//         href="https://kahoot.it/challenge/02917910"
//         target="_blank"
//         sx={{
//           backgroundColor: '#eb6445',
//           borderRadius: '30px',
//           padding: { xs: '10px 8px', md: '30px 40px' },
//           zIndex: 2,
//           mb: 2,
//           boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
//           '&:hover': {
//             backgroundColor: '#c9553a',
//           },
//         }}
//       >
//         <Typography sx={{ color: 'white', fontSize: { md: 50, xs: '2em' } }}>
//           امتحان تجريبي
//         </Typography>
//       </Button>

//       {/* Button to Start Quiz */}
//       <Button
//         onClick={handleClick}
//         sx={{
//           backgroundColor: '#FBC029',
//           borderRadius: '30px',
//           padding: { xs: '15px 40px', md: '40px 120px' },
//           zIndex: 2,
//           mb: 2,
//           boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
//           '&:hover': {
//             backgroundColor: '#e0a828',
//           },
//         }}
//       >
//         <Box
//           component="img"
//           src={startButtonImage}
//           alt="Start"
//           sx={{ width: { xs: '80px', md: '120px' }, height: 'auto' }}
//         />
//       </Button>

//       {/* Explanatory Text */}
//       <Typography
//         variant="h5"
//         sx={{
//           color: '#064DA1',
//           fontWeight: 'bold',
//           textAlign: 'center',
//           mt: 2,
//           zIndex: 2,
//           fontSize: { xs: '1.5rem', md: '2rem' },
//         }}
//       >
//         لتبدأ المسابقة اضغط هنا
//       </Typography>
//     </Box>
//   );
// }

// export default Footer;
