

import { Box, Typography } from '@mui/material';
import React from 'react';
import labtopImage from '../assets/laptop.svg'; // Replace with the actual path to your image

function ParticipationInfo () {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
      
        paddingBottom:10,
        
        backgroundColor: 'rgba(255, 255, 255, 0.2)', // Semi-transparent background
        backdropFilter: 'blur(3px)', // Blurred effect
        flexDirection: { xs: 'column', md: 'row' },
      }}
    >
      {/* Links section, vertically centered */}
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          margin:10,
          justifyContent: 'center', // Center the links vertically
          marginBottom: { xs: '20px', md: 0 },
          marginTop:15,
          zIndex: 10,
        }}
      >
        {[
          { href: 'https://www.eu-arabic.org/about.html', text: 'https://www.eu-arabic.org/about.html' },
          { href: 'https://ar.wikipedia.org/wiki/الاتحاد_الأوروبي', text: 'https://ar.wikipedia.org/wiki/الاتحاد_الأوروبي' },
          { href: 'https://europa.eu/teachers-corner/age-ranks/ages-9-12_en', text: 'https://europa.eu/teachers-corner/age-ranks/ages-9-12_en' },
          { href: 'https://european-union.europa.eu/easy-read_en', text: 'https://european-union.europa.eu/easy-read_en' },
          { href: 'https://www.britannica.com/topic/European-Union', text: 'https://www.britannica.com/topic/European-Union' },
        ].map((link, index) => (
          <a
            key={index}
            href={link.href}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: '#064DA1',
              fontWeight: 'bold',
              display: 'block',
              marginBottom: '10px',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
          >
            {link.text}
          </a>
        ))}
      </Box>

      {/* Right Side - Text and Image */}
      <Box sx={{   margin:10,flex: 1, textAlign: { xs: 'center', md: 'right' }, paddingLeft: { md: '20px' } }}>
        <Typography variant="h4" sx={{ color: '#E55C4D', fontWeight: 'bold', marginBottom: '10px' }}>
          عشان تعرفوا أكثر
        </Typography>
        <Typography variant="h4" sx={{ color: '#E55C4D', fontWeight: 'bold', marginBottom: '20px' }}>
          الروابط المرفقة فيها كل المعلومات
        </Typography>
        <Box
          component="img"
          src={labtopImage}
          alt="Footer Decoration"
          sx={{
            width: { xs: '80%', md: '50%' },
            height: 'auto',
            margin: { xs: '0 auto', md: '0' },
            marginTop: '15px',
          }}
        />
      </Box>
    </Box>
  );
}

export default ParticipationInfo;
