// src/components/CountdownSection.js
import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import clockBackground from '../assets/clock_countdown.svg'; // Clock background image
import leftDecoration from '../assets/left_countdown.svg'; // Left decoration image

function CountdownSection({ onCountdownEnd }) {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const targetDate = new Date("2024-11-28T09:00:00"); // Set the target date and time
    const interval = setInterval(() => {
      const now = new Date();
      const difference = targetDate - now;

      if (difference > 0) {
        setDays(Math.floor(difference / (1000 * 60 * 60 * 24)));
        setHours(Math.floor((difference / (1000 * 60 * 60)) % 24));
        setMinutes(Math.floor((difference / 1000 / 60) % 60));
        setSeconds(Math.floor((difference / 1000) % 60));
      } else {
        clearInterval(interval);
        onCountdownEnd(); // Trigger the callback function when countdown ends
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [onCountdownEnd]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '70px',
        position: 'relative',
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        backdropFilter: 'blur(3px)',
        borderRadius: '10px',
      }}
    >
      {/* Left Decoration Image */}
      <Box
        component="img"
        src={leftDecoration}
        alt="Left Decoration"
        sx={{
          position: 'absolute',
          left: 0,
          top: '10px',
          maxHeight: '300px',
          objectFit: 'contain',
          zIndex: 1,
          '@media (max-width: 768px)': {
            maxHeight: '100px', // Smaller on mobile
          },
        }}
      />

      {/* Clock Background Image */}
      <Box
        sx={{
          position: 'absolute',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          zIndex: 0,
        }}
      >
        <Box
          component="img"
          src={clockBackground}
          alt="Clock Background"
          sx={{
            maxWidth: '400px', // Increased size
            width: '100%',
            height: 'auto',
            opacity: 0.5, // Set opacity
          }}
        />
      </Box>

      {/* Countdown Content */}
      <Box sx={{ zIndex: 2, textAlign: 'center' }}>
        <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#064DA1', mb: 2 }}>
          ممكن تربحوا جوائز قيمة
        </Typography>

        {/* Countdown Timer */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            width: '100%',
            maxWidth: '500px',
            backgroundColor: 'rgba(6, 77, 161, 0.7)', // Blue background with opacity
            color: '#FFFFFF',
            borderRadius: '10px',
            padding: '15px 0',
            mt: 2,
          }}
        >
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
              {days.toString().padStart(2, '0')}
            </Typography>
            <Typography variant="h6">أيام</Typography>
          </Box>
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
              {hours.toString().padStart(2, '0')}
            </Typography>
            <Typography variant="h6">ساعات</Typography>
          </Box>
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
              {minutes.toString().padStart(2, '0')}
            </Typography>
            <Typography variant="h6">دقائق</Typography>
          </Box>
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
              {seconds.toString().padStart(2, '0')}
            </Typography>
            <Typography variant="h6">ثواني</Typography>
          </Box>
        </Box>

        <Typography
          variant="h5"
          sx={{
            color: '#064DA1',
            fontWeight: 'bold',
            textAlign: 'center',
            marginTop: '15px',
            fontSize: { xs: '1rem', sm: '1.5rem', md: '2rem' },
          }}
        >
          لحقوا حالكم
        </Typography>
      </Box>
    </Box>
  );
}

export default CountdownSection;
