import { Box } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import backgroundImage from './assets/background.svg'; // Import your background image
import ComposableMapEurope from './components/ComposableMapEurope';
import CountdownSection from './components/CountdownSection';
import Footer from './components/Footer';
import Header from './components/Header';
import ParticipationInfo from './components/ParticipationInfo';
import QuestionSection from './components/QuestionSection';

function App() {
  const countdownSectionRef = useRef(null);
  const footerRef = useRef(null);
  const [countdownEnded, setCountdownEnded] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  // Function to handle the end of the countdown
  const handleCountdownEnd = () => {
    setCountdownEnded(true);
    // Scroll to footer when countdown ends
    if (footerRef.current) {
      footerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Scroll listener to add blur when scrolled
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Box
      className="App"
      sx={{
        position: 'relative',
        width: '100vw',
        minHeight: '100vh',
        overflowX: 'hidden',
        bgcolor: '#f1f1f1',
        '&::before': {
          content: '""',
          position: 'absolute', 
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
          zIndex: 0,
        },
      }}
    >
      <div 
        style={{
          position: 'fixed', // Fix the header to the top
          top: 0,
          width: '100%',
          zIndex: 10,
          padding: '10px',
          boxSizing: 'border-box',
          backgroundColor: isScrolled ? 'rgba(255, 255, 255, 0.8)' : 'transparent', // Change background when scrolled
          backdropFilter: isScrolled ? 'blur(5px)' : 'none', // Apply blur when scrolled
          transition: 'background-color 0.3s ease, backdrop-filter 0.3s ease', // Smooth transition
        }}
      >
        <Header />
      </div>
      
      {/* Europe Map Section */}
      <Box sx={{ position: 'relative', overflow: 'hidden', zIndex: 1 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
            backdropFilter: 'blur(3px)', 
            zIndex: 10,
          }}
        >
          <ComposableMapEurope />
        </Box>
        <QuestionSection />
      </Box>

      {/* Countdown Section with ref attached */}
      <Box ref={countdownSectionRef}>
        <CountdownSection onCountdownEnd={handleCountdownEnd} />
      </Box>

      {/* Participation Info and Footer */}
      <Box ref={footerRef}>
        <ParticipationInfo />
        <Footer countdownEnded={countdownEnded} countdownSectionRef={countdownSectionRef} />
      </Box>
    </Box>
  );
}

export default App;
