// src/components/EuropeMap.js
import React, { useState } from 'react';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import leftFlags from '../assets/left_flags.svg'; // Import the left image
import europeMap from './europe-map-topo.json'; // Load your TopoJSON file of Europe

function ComposableMapEurope() {
  const countryColors = {
    FRA: "#E55C4D", ESP: "#FBC029", PRT: "#FFA500", DEU: "#4D5DCE",
    ITA: "#66B032", IRL: "#66B032", POL: "#FF9933", RUS: "#D2DCE2",
    NOR: "#66B032", SWE: "#FFD700", FIN: "#9393C4", GRC: "#FFA500",
    TUR: "#E55C4D", NLD: "#66B032", BEL: "#66B032", CHE: "#66B032",
    AUT: "#66B032", CZE: "#4D5DCE", HUN: "#FFD700", SRB: "#E55C4D",
    BGR: "#FFA500", DNK: "#4D5DCE", LUX: "#66B032", SVK: "#FFA500",
    SVN: "#66B032", HRV: "#FFD700", BIH: "#E55C4D", EST: "#77B95A",
    LVA: "#4D5DCE", LTU: "#66B032",
  };

  const excludedCountries = ["GBR"];
  const [hoveredCountry, setHoveredCountry] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      {/* Magnifying Glass with Bouncing Animation */}
      <div
        style={{
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)', // Center it on the map
          zIndex: 10,
          animation: 'bounce 2s infinite', // Bouncing animation
        }}
      >
        {/* <img
          src={magnifyingGlass}
          alt="Magnifying Glass"
          style={{
            width: '1000px', // Size for desktop
            height: 'auto',
            '@media (max-width: 768px)': {
              width: '50px', // Smaller size on mobile
            },
          }}
        /> */}
      </div>

      {/* Left Flags image positioned absolutely and centered vertically with responsive sizing */}
      <div 
        style={{
          position: 'absolute',
          left: 0,
          top: '50%',
          transform: 'translateY(-50%)', // Center the image vertically
          zIndex: 10,
        }}
      >
        <img 
          src={leftFlags} 
          alt="Left Flags" 
          style={{ 
            height: 'auto',
            maxWidth: '100%', 
            marginTop:50,
            maxHeight:200,
            '@media (max-width: 300px)': {
              width: '50px', // Smaller width for mobile
            },
          }} 
        />
      </div>

      {/* The map itself */}
      <ComposableMap
        projection="geoAzimuthalEqualArea"
        projectionConfig={{
          rotate: [-20.0, -52.0, 0],
          scale: 700,
        }}
        style={{ width: "100%", height: "auto" }}
      >
        <Geographies geography={europeMap}>
          {({ geographies }) =>
            geographies
              .filter((geo) => !excludedCountries.includes(geo.properties.adm0_a3))
              .map((geo) => {
                const isoCode = geo.properties.adm0_a3;
                const fillColor = countryColors[isoCode] || "#D3D3D3";

                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    tabIndex={-1}
                    focusable={false}
                    style={{
                      default: { fill: fillColor, stroke: "#FFF", strokeWidth: 0.5 },
                      hover: { fill: fillColor, transition: "fill 0.3s ease" },
                      focus: { outline: "none" },
                    }}
                    onMouseEnter={(event) => {
                      setHoveredCountry({
                        name: geo.properties.name || geo.properties.NAME,
                        isoCode: isoCode,
                      });
                      setTooltipPosition({
                        x: event.clientX,
                        y: event.clientY,
                      });
                    }}
                    onMouseMove={(event) => {
                      setTooltipPosition({
                        x: event.clientX,
                        y: event.clientY,
                      });
                    }}
                    onMouseLeave={() => {
                      setHoveredCountry(null);
                    }}
                  />
                );
              })
          }
        </Geographies>
      </ComposableMap>

      {/* Tooltip for displaying hovered country information */}
      {hoveredCountry && (
        <div
          style={{
            position: 'absolute',
            top: tooltipPosition.y + 10,
            left: tooltipPosition.x + 10,
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            color: '#FFF',
            padding: '5px 10px',
            borderRadius: '5px',
            pointerEvents: 'none',
            fontSize: '12px',
            zIndex: 10,
          }}
        >
          <strong>{hoveredCountry.name}</strong> ({hoveredCountry.isoCode})
        </div>
      )}
    </div>
  );
}

export default ComposableMapEurope;
