// src/components/Header.js
import { Box } from '@mui/material';
import React from 'react';
import titleLogo from '../assets/logo.svg'; // "اعرف أوروبا" logo
import logo2 from '../assets/logo_europe.svg';
import logo1 from '../assets/ministry_logo.png';


function Header() {
  return (
    <Box
      component="header"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        paddingX: { xs: 2, md: 4 },
        paddingY: 2,
        backgroundColor: 'transparent', // Explicitly transparent background
        boxSizing: 'border-box',
        overflow: 'hidden',
      }}
    >
      {/* Left logos */}
      <Box
        sx={{
          display: 'flex',
          gap: { xs: 1, md: 2 },
          alignItems: 'center',
        }}
      >
        <Box
          component="img"
          src={logo1}
          alt="Logo 1"
          sx={{
            height: { xs: 40, sm: 50, md: 60 },
            maxWidth: { xs: '50px', sm: '60px', md: '80px' },
          }}
        />
        <Box
          component="img"
          src={logo2}
          alt="Logo 2"
          sx={{
            height: { xs: 40, sm: 50, md: 60 },
            maxWidth: { xs: '50px', sm: '60px', md: '80px' },
          }}
        />
      </Box>
      
      {/* Title Logo (اعرف أوروبا) positioned at the end */}
      <Box
        component="img"
        src={titleLogo}
        alt="اعرف أوروبا"
        sx={{
          height: { xs: 40, sm: 50, md: 60 },
          maxWidth: '100%',
          objectFit: 'contain',
          marginRight: 0,
          marginLeft: 'auto',
        }}
      />
    </Box>
  );
}

export default Header;
